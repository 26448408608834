.btn {
  border: none;
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  padding: 16px 24px;

  &-primary {
    background-color: #0055AD;
    color: #fff;
  }
}
