.page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  &-body {
    flex: 1;
    overflow-y: auto;
  }
}
